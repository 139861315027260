import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { GlobalLayout, WorkLayout } from 'components/Layout';
import { Banner, Images, Header } from 'components/Works';

function RuCenter() {
  return (
    <GlobalLayout>
      <WorkLayout>
        <Banner
          render={({ onLoad, onStartLoad }) => (
            <StaticImage
              placeholder='none'
              onStartLoad={onStartLoad}
              onLoad={onLoad}
              alt='Desktop main page'
              src='../../images/nic.png'
            />
          )}
        />

        <Header title='RuCenter' link='https://nic.ru' />

        <p>
          RuCenter is one of Russia’s largest and oldest hosting and cloud
          infrastructure provider. And as any many old digital companies, it
          suffered from having outdated UI. To solve this, my team was given a
          task to fully recreate one of the companies most important service -
          hosting control panel.
        </p>
        <h2>The goal</h2>
        <p>
          We were given the new design of the dashboard and legacy API. Our task
          was to utilize the old API to create a new one, that will be used by
          the frontend and develop the dashboard UI. The dashboard consisted of
          many features, such as wizard for adding and managing websites, mail
          manager, file manager and backups. After the hosting dashboard was
          launched my team was also asked to create a dashboard for the
          dedicated & virtual servers management.
        </p>

        <h2>My role</h2>
        <p>
          At the start of the project I was working as a frontend lead and
          developer, managing other frontend developers and contributing to the
          development at the same time. However, I am always looking for trying
          out new things and by the end of the project I have taken the
          responsibility as a project manager, communicating with the client,
          delegating the tasks and controlling their execution, even for
          backend.
        </p>

        <Images description='Backups screen'>
          <StaticImage
            alt='RuCenter Backups'
            src='../../images/nic/screen-1.png'
          />
        </Images>

        <h2>Technologies</h2>
        <p>
          Having really good development experience with React.js, there was no
          reason not to choose it. The library stack was good for the project’s
          goals and allowed to implement all the features. However, the
          immutable.js library was dropped, because in the previous project it
          has brought too much complexity, but hasn’t shown enough benefits.
        </p>
        <p>
          At first my team’s task was to develop the UI only for the hosting’s
          dashboard, but later the company has decided to start a parallel
          project of creating a dashboard for the dedicated & virtual servers.
          Since the dashboard originally already had all the necessary
          components developed, it didn’t seem right to copy & paste it to
          another project and maintain two independent codebases for the
          components.
        </p>
        <p>
          However, at the time React.js was too young and approaches with
          creating a UI component library seemed overly complex and we didn’t
          have enough time for it. To solve the problem I decided to move the
          components into a separate git repository and connect it to both
          applications via git subtree. Looking back at it, it was not the right
          solution as there have been so many file conflicts and other problems
          when trying to synchronise the changes, but it was useful experience
          and it solved the problem.
        </p>
        <p>The final frontend stack of the project looked like this:</p>
        <ul>
          <li>React.js</li>
          <li>CSS modules combined with Stylus for styling</li>
          <li>Redux for state management combined with Immutable.js</li>
        </ul>

        <Images description='Resources allocation & usage screen'>
          <StaticImage
            alt='RuCenter Resources'
            src='../../images/nic/screen-2.png'
          />
        </Images>

        <h2>Result</h2>
        <p>
          Both dashboards were successfully developed and launched. Because
          there were so many users that have already been using the old
          dashboard, the transition was not instant. Instead users were offered
          to try out the new dashboard and had the opportunity to switch back.
          This allowed us to gather feedback from real users and fix many bugs
          and issues before the new dashboard had replaced the old one entirely.
        </p>
      </WorkLayout>
    </GlobalLayout>
  );
}

export default RuCenter;
